
import React, { useEffect } from "react";
import SEO from "../components/SEO";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Gallery from "../components/Gallery";
import SubscribeForm from "../components/SubscribeForm";
import Footer from "../components/Footer";
import Stats from "../components/Stats";
import MapSection from "../components/MapSection";
import Donation from "../components/Donation";
import Activities from "../components/Activities";
import Testimonial from "../components/Testimonial";

const Index: React.FC = () => {
  // Add smooth scroll behavior for internal links
  useEffect(() => {
    const handleAnchorClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const anchor = target.closest('a');
      
      if (anchor && anchor.hash && anchor.hash.startsWith('#') && anchor.href.includes(window.location.pathname)) {
        e.preventDefault();
        
        const targetElement = document.querySelector(anchor.hash);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.getBoundingClientRect().top + window.scrollY - 80, // Offset for fixed header
            behavior: 'smooth'
          });
          
          // Update URL without scrolling
          history.pushState(null, '', anchor.hash);
        }
      }
    };

    document.addEventListener('click', handleAnchorClick);
    
    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, []);

  return (
    <>
      <SEO 
        title="GreenWild - Prenons soin de notre planète ensemble"
        description="GreenWild est une association engagée dans la préservation de l'environnement. Rejoignez-nous pour participer à des actions concrètes de nettoyage et de sensibilisation."
        keywords="environnement, écologie, nettoyage, nature, biodiversité, association, bénévolat, protection, action écologique, planète"
      />
      <div className="min-h-screen bg-white overflow-x-hidden">
        <header>
          <Navbar />
        </header>
        <main>
          <Hero />
          <Features />
          <Stats />
          <Activities />
          <MapSection />
          <Testimonial />
          <Donation />
          <Gallery />
          <SubscribeForm />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Index;
