
import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { galleryApi } from "@/services/api";

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const sectionRef = useRef<HTMLDivElement>(null);
  
  // Fetch gallery items from API
  const { data: galleryItems = [], isLoading } = useQuery({
    queryKey: ['galleryItems'],
    queryFn: galleryApi.getAll,
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 2
  });

  // Use placeholder data if no data from API
  const placeholderItems = [
    {
      id: "1",
      title: "Randonnée en forêt",
      tags: ["Randonnée"],
      imageUrl: "https://images.unsplash.com/photo-1513836279014-a89f7a76ae86",
      description: "Découverte des sentiers cachés et de la faune locale",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "2",
      title: "Le Rocher des deux trous",
      tags: ["Randonnée"],
      imageUrl: "/lovable-uploads/deux_trous.jpg",
      description: "Nettoyage lors d'une randonnée aux Rocher des deux trous",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "2",
      title: "L'équipe",
      tags: ["Equipe"],
      imageUrl: "/lovable-uploads/deux_trous_team.jpg",
      description: "Nettoyage lors d'une randonnée aux Rocher des deux trous",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "3",
      title: "Randonnée en montagne",
      tags: ["Randonnée"],
      imageUrl: "https://images.unsplash.com/photo-1469474968028-56623f02e42e",
      description: "Nettoyage de sentier montagnard",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "4",
      title: "Pêche à l'aimant dans le Vidourle",
      tags: ["Pêche à l'aimant"],
      imageUrl: "/lovable-uploads/mobylette_peche.jpg",
      description: "Dépollution d'une partie du vidourle, mobylette pêché!.",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "5",
      title: "Balade et découverte du site d'Ambrussum",
      tags: ["Balade"],
      imageUrl: "/lovable-uploads/ambrussum_balade.jpg",
      description: "Ramassage des déchets sur site archéologique d'ambrussum",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "6",
      title: "L'équipe à Ambrussum",
      tags: ["Equipe"],
      imageUrl: "/lovable-uploads/ambrussum_team.png",
      description: "Ramassage des déchets sur site archéologique d'ambrussum",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
    {
      id: "7",
      title: "Le Rocher des deux trous",
      tags: ["Randonnée"],
      imageUrl: "/lovable-uploads/deux_trous.jpg",
      description: "Nettoyage lors d'une randonnée aux Rocher des deux trous",
      date: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    },
  ];

  // Use galleryItems if available, otherwise use placeholderItems
  const items = galleryItems.length > 0 ? galleryItems : placeholderItems;

  // Get all unique categories from tags
  const categories = ["Tous", ...new Set(items.flatMap(item => item.tags || []))];

  // Filter items based on selected category
  const filteredItems = selectedCategory && selectedCategory !== "Tous"
    ? items.filter(item => item.tags && item.tags.includes(selectedCategory))
    : items;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // Clear previous visible items first
          setVisibleItems([]);
          
          // Start revealing items with a staggered delay
          filteredItems.forEach((_, index) => {
            setTimeout(() => {
              setVisibleItems(prev => [...prev, index]);
            }, 150 * index);
          });
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [filteredItems]); // Ne pas inclure visibleItems ici, c'était la cause de la boucle infinie

  // Handle category change
  const handleCategoryChange = (category: string | null) => {
    setSelectedCategory(category);
    setVisibleItems([]); // Clear visible items
    
    // Small delay to allow for animation reset
    setTimeout(() => {
      const filtered = category && category !== "Tous"
        ? items.filter(item => item.tags && item.tags.includes(category))
        : items;
        
      filtered.forEach((_, index) => {
        setTimeout(() => {
          setVisibleItems(prev => [...prev, index]);
        }, 150 * index);
      });
    }, 50);
  };

  const openModal = (index: number) => {
    setActiveIndex(index);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setActiveIndex(null);
    document.body.style.overflow = "unset";
  };

  const handleNext = () => {
    if (activeIndex !== null) {
      setActiveIndex((activeIndex + 1) % filteredItems.length);
    }
  };

  const handlePrev = () => {
    if (activeIndex !== null) {
      setActiveIndex((activeIndex - 1 + filteredItems.length) % filteredItems.length);
    }
  };

  return (
    <section id="gallery" ref={sectionRef} className="py-24 bg-gray-50">
      <div className="container-custom">
        <div className="flex flex-col items-center text-center mb-12">
          <span className="inline-block px-3 py-1 mb-4 text-sm font-medium bg-green-100 text-green-800 rounded-full">
            Nos Aventures
          </span>
          <h2 className="section-title mb-4">
            Explorez notre galerie d'expériences
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mb-8 text-balance">
            Découvrez en images les moments inoubliables vécus lors de nos
            aventures en pleine nature.
          </p>

          {/* Category filters */}
          <div className="flex flex-wrap justify-center gap-2 mb-8">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => handleCategoryChange(category === "Tous" ? null : category)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                  (category === "Tous" && selectedCategory === null) || 
                  category === selectedCategory
                    ? "bg-green-700 text-white"
                    : "bg-white border border-green-200 text-green-800 hover:bg-green-50"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Gallery grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredItems.map((item, index) => (
            <div
              key={item.id}
              className={`overflow-hidden rounded-xl shadow-sm cursor-pointer transition-all duration-700 transform ${
                visibleItems.includes(index)
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
              onClick={() => openModal(index)}
            >
              <div className="image-zoom-container">
                <div className="relative aspect-w-4 aspect-h-3">
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="image-zoom w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-transparent to-transparent opacity-80"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                    <span className="text-xs font-medium bg-green-700/80 px-3 py-1 rounded-full mb-2 inline-block">
                      {item.tags && item.tags[0]}
                    </span>
                    <h3 className="text-xl font-bold">{item.title}</h3>
                    <p className="text-white/80 text-sm mt-1">{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for enlarged view */}
      {activeIndex !== null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/90 p-4 animate-fade-in">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white z-50 p-2"
            aria-label="Fermer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>

          <button
            onClick={handlePrev}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white z-50 p-2"
            aria-label="Précédent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>

          <button
            onClick={handleNext}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white z-50 p-2"
            aria-label="Suivant"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>

          <div className="max-w-5xl w-full animate-scale-in">
            <img
              src={filteredItems[activeIndex].imageUrl}
              alt={filteredItems[activeIndex].title}
              className="w-full object-contain max-h-[80vh]"
            />
            <div className="bg-white p-4 mt-4 rounded-lg">
              <h3 className="text-xl font-bold">{filteredItems[activeIndex].title}</h3>
              <p className="text-gray-600 mt-1">{filteredItems[activeIndex].description}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Gallery;
