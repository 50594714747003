
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ArrowLeft, Image as ImageIcon, Plus, X } from 'lucide-react';
import { galleryApi } from '@/services/api';
import { GalleryItem } from '@/types/database';

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent } from '@/components/ui/card';

// Form validation schema
const galleryFormSchema = z.object({
  title: z.string().min(1, 'Le titre est requis'),
  description: z.string().optional(),
  imageUrl: z.string().url('L\'URL de l\'image est invalide').min(1, 'L\'URL de l\'image est requise'),
  date: z.string().min(1, 'La date est requise'),
  tags: z.array(z.string()).min(1, 'Au moins un tag est requis'),
});

type GalleryFormValues = z.infer<typeof galleryFormSchema>;

interface GalleryFormProps {
  galleryItem?: GalleryItem;
}

const GalleryForm: React.FC<GalleryFormProps> = ({ galleryItem }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [newTag, setNewTag] = useState('');
  
  const isEditMode = !!galleryItem;
  
  // Format date string for input (YYYY-MM-DD)
  const formatDateForInput = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Get YYYY-MM-DD part
  };
  
  // Initialize form
  const form = useForm<GalleryFormValues>({
    resolver: zodResolver(galleryFormSchema),
    defaultValues: {
      title: galleryItem?.title || '',
      description: galleryItem?.description || '',
      imageUrl: galleryItem?.imageUrl || '',
      date: galleryItem?.date ? formatDateForInput(galleryItem.date) : new Date().toISOString().split('T')[0],
      tags: galleryItem?.tags || [],
    },
  });
  
  // Mutations for creating and updating gallery items
  const createItemMutation = useMutation({
    mutationFn: (data: GalleryFormValues) => {
      // Fix: Ensure all required properties are set and not optional
      const newItem: Omit<GalleryItem, 'id' | 'createdAt' | 'updatedAt'> = {
        title: data.title, // Explicitly set as non-optional
        description: data.description,
        imageUrl: data.imageUrl,
        date: new Date(data.date).toISOString(),
        tags: data.tags,
      };
      return galleryApi.create(newItem);
    },
    onSuccess: () => {
      toast.success('Image ajoutée avec succès');
      queryClient.invalidateQueries({ queryKey: ['galleryItems'] });
      navigate('/admin/gallery');
    },
    onError: (error) => {
      console.error('Error creating gallery item:', error);
      toast.error('Erreur lors de l\'ajout de l\'image');
    },
  });
  
  const updateItemMutation = useMutation({
    mutationFn: (data: GalleryFormValues) => {
      if (!galleryItem) throw new Error('Missing gallery item');
      
      // Fix: Pass the id as the first argument and updated data as the second argument
      return galleryApi.update(galleryItem.id, {
        ...data,
        date: new Date(data.date).toISOString(),
      });
    },
    onSuccess: () => {
      toast.success('Image mise à jour avec succès');
      queryClient.invalidateQueries({ queryKey: ['galleryItems'] });
      navigate('/admin/gallery');
    },
    onError: (error) => {
      console.error('Error updating gallery item:', error);
      toast.error('Erreur lors de la mise à jour de l\'image');
    },
  });
  
  // Handle form submission
  const onSubmit = (data: GalleryFormValues) => {
    if (isEditMode) {
      updateItemMutation.mutate(data);
    } else {
      createItemMutation.mutate(data);
    }
  };
  
  // Handle adding new tag
  const handleAddTag = () => {
    const trimmedTag = newTag.trim();
    if (!trimmedTag) return;
    
    const currentTags = form.getValues().tags || [];
    
    // Check if tag already exists
    if (currentTags.includes(trimmedTag)) {
      toast.error('Ce tag existe déjà');
      return;
    }
    
    form.setValue('tags', [...currentTags, trimmedTag]);
    setNewTag('');
  };
  
  // Handle removing a tag
  const handleRemoveTag = (tagToRemove: string) => {
    const currentTags = form.getValues().tags;
    form.setValue(
      'tags', 
      currentTags.filter(tag => tag !== tagToRemove)
    );
  };
  
  // Handle key press in tag input
  const handleTagKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      handleAddTag();
    }
  };
  
  return (
    <div className="container py-6">
      <div className="flex flex-col space-y-6">
        {/* Header with title and back button */}
        <div className="flex items-center gap-4">
          <Button variant="outline" size="icon" onClick={() => navigate('/admin/gallery')}>
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-2xl font-bold">
            {isEditMode ? 'Modifier l\'image' : 'Ajouter une image'}
          </h1>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                {/* Basic info section */}
                <div className="space-y-4">
                  <h2 className="text-lg font-semibold">Informations générales</h2>
                  
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Titre</FormLabel>
                        <FormControl>
                          <Input placeholder="Titre de l'image" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea 
                            placeholder="Description de l'image" 
                            className="resize-none" 
                            {...field} 
                            value={field.value || ''}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="imageUrl"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>URL de l'image</FormLabel>
                        <FormControl>
                          <Input placeholder="https://example.com/image.jpg" {...field} />
                        </FormControl>
                        <FormDescription>
                          Entrez l'URL complète de l'image
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Date</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                
                {/* Tags section */}
                <div className="space-y-4">
                  <h2 className="text-lg font-semibold">Tags</h2>
                  <FormField
                    control={form.control}
                    name="tags"
                    render={() => (
                      <FormItem>
                        <FormLabel>Tags</FormLabel>
                        <div className="flex gap-2">
                          <Input
                            placeholder="Ajouter un tag"
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            onKeyDown={handleTagKeyPress}
                            className="flex-1"
                          />
                          <Button 
                            type="button" 
                            onClick={handleAddTag}
                            variant="outline"
                          >
                            <Plus className="h-4 w-4 mr-2" />
                            Ajouter
                          </Button>
                        </div>
                        
                        <div className="flex flex-wrap gap-2 mt-2">
                          {form.watch('tags')?.map((tag) => (
                            <div 
                              key={tag} 
                              className="flex items-center gap-1 px-3 py-1 rounded-full bg-green-100 text-green-800"
                            >
                              <span>{tag}</span>
                              <button
                                type="button"
                                onClick={() => handleRemoveTag(tag)}
                                className="text-green-800 hover:text-red-500"
                              >
                                <X className="h-3 w-3" />
                              </button>
                            </div>
                          ))}
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                
                {/* Submit button */}
                <div className="flex justify-end gap-2">
                  <Button 
                    type="button" 
                    variant="outline" 
                    onClick={() => navigate('/admin/gallery')}
                  >
                    Annuler
                  </Button>
                  <Button 
                    type="submit" 
                    disabled={createItemMutation.isPending || updateItemMutation.isPending}
                  >
                    {(createItemMutation.isPending || updateItemMutation.isPending) 
                      ? 'Enregistrement...' 
                      : isEditMode ? 'Mettre à jour' : 'Ajouter'}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
          
          {/* Preview section */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Aperçu</h2>
            <Card>
              <CardContent className="p-0 overflow-hidden">
                {form.watch('imageUrl') ? (
                  <div className="relative">
                    <img 
                      src={form.watch('imageUrl')} 
                      alt={form.watch('title')} 
                      className="w-full aspect-video object-cover"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = 'https://placehold.co/600x400?text=Image+invalide';
                      }}
                    />
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black/50 transition-opacity">
                      <Button 
                        variant="secondary" 
                        size="sm" 
                        onClick={() => window.open(form.watch('imageUrl'), '_blank')}
                      >
                        Voir en taille réelle
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-[200px] bg-muted">
                    <ImageIcon className="h-12 w-12 text-muted-foreground opacity-50" />
                    <p className="text-sm text-muted-foreground mt-2">Aperçu non disponible</p>
                  </div>
                )}
              </CardContent>
            </Card>
            
            <div className="mt-4">
              <h3 className="font-medium">{form.watch('title') || 'Titre non défini'}</h3>
              {form.watch('description') && (
                <p className="text-sm text-gray-600 mt-1">{form.watch('description')}</p>
              )}
              <div className="flex flex-wrap gap-1 mt-2">
                {form.watch('tags')?.map((tag) => (
                  <span 
                    key={tag} 
                    className="inline-block px-2 py-0.5 text-xs font-medium rounded-full bg-green-100 text-green-800"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryForm;
